import React from 'react';
import './style.scss';
import vestingLogo from '../../assets/img/VestingLogo.png';
import { CButton } from '@coreui/react';
import { useEthers } from '@usedapp/core';

import {
  useClaimRewardMutation,
  useGetAvailableReward,
  useGetClaimableReward,
  useGetClaimedReward,
} from '../../queries/vesting';
import NglAmount from '../../components/NglAmount';

const Vesting = () => {
  const { activateBrowserWallet, account } = useEthers();
  const { mutate: claimReward } = useClaimRewardMutation();
  const { data: claimableReward, isLoading: isClaimableLoading } = useGetClaimableReward(account ?? '');
  const { data: claimedReward, isLoading: isClaimedLoading } = useGetClaimedReward(account ?? '');
  const { data: availableReward, isLoading: isAvailableLoading } = useGetAvailableReward(account ?? '');

  return (
    <div className="vesting-bg d-flex flex-column justify-content-center align-items-center">
      <div className="box d-flex flex-column align-items-center">
        <div className="box__header box__text--oswald">NGL DISTRIBUTION PORTAL</div>
        {account ? (
          <div className="box__body--connected d-flex flex-column align-items-center">
            <div
              className="box__text--underlay box__text--oswald w-100"
              style={{ marginTop: '35px', marginBottom: '20px', fontSize: '1rem' }}
            >
              {account}
            </div>

            <div className="d-flex flex-column align-items-center">
              <div className="d-flex">
                <div
                  className="box__text box__text--oswald d-flex flex-column justify-content-center"
                  style={{ fontSize: '1rem' }}
                >
                  Available:&nbsp;
                </div>
                <p className="box__text--passeroone" style={{ fontSize: '1.5rem', marginBottom: 'unset' }}>
                  <NglAmount amount={availableReward ?? '0'} isLoadingAmount={isAvailableLoading} /> NGL
                </p>
              </div>
              <div className="d-flex">
                <div
                  className="box__text box__text--oswald d-flex flex-column justify-content-center"
                  style={{ fontSize: '1rem' }}
                >
                  Claimed:&nbsp;
                </div>
                <p className="box__text--passeroone" style={{ fontSize: '1.5rem', marginBottom: 'unset' }}>
                  <NglAmount amount={claimedReward ?? '0'} isLoadingAmount={isClaimedLoading} /> NGL
                </p>
              </div>
              <div className="d-flex">
                <div
                  className="box__text box__text--oswald d-flex flex-column justify-content-center"
                  style={{ fontSize: '1rem' }}
                >
                  Claimable:&nbsp;
                </div>
                <p className="box__text--passeroone" style={{ fontSize: '1.5rem', marginBottom: 'unset' }}>
                  <NglAmount amount={claimableReward ?? '0'} isLoadingAmount={isClaimableLoading} />
                  NGL
                </p>
              </div>
            </div>
            <CButton
              onClick={() => {
                claimReward(account);
              }}
              className="box__button"
              disabled={claimableReward?.isZero()}
            >
              Claim
            </CButton>
          </div>
        ) : (
          <div className="box__body--connect d-flex flex-column align-items-center">
            <img src={vestingLogo} alt="vesting-logo" />
            <div className="box__text--underlay w-100" style={{ marginTop: '20px', marginBottom: '15px' }}>
              Claim your vesting
            </div>
            <div className="box__text">
              Let us detect if you have any unclaimed NGL in public,
              <br /> private or seed round by connecting to your wallet first.
            </div>
            <CButton onClick={() => activateBrowserWallet()} className="box__button">
              Connect Wallet
            </CButton>
          </div>
        )}
        <div className="box__underline" />
      </div>
    </div>
  );
};

export default Vesting;
