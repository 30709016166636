import BigNumber from 'bignumber.js';
import { useQuery, UseQueryResult } from 'react-query';
import { Account } from 'web3-core';
import { nglContract } from './contracts';

export const useNglDecimalsQuery = (): UseQueryResult<BigNumber> => {
  return useQuery('nglDecimals', async () => {
    const decimals = await nglContract.methods.decimals().call();
    return new BigNumber(10).pow(decimals);
  });
};

export type GetNGLTypes = {
  wallet: Account | undefined;
  decimals: BigNumber;
};
