import GOERLI_VESTING_CONTRACT_ADDRESS from './contracts/GoldFeverVesting.goerli.address';
import GOERLI_NGL_CONTRACT_ADDRESS from './contracts/GoldFeverNativeGoldRoot.goerli.address';
import MAINNET_VESTING_CONTRACT_ADDRESS from './contracts/GoldFeverVesting.mainnet.address';
import MAINNET_NGL_CONTRACT_ADDRESS from './contracts/GoldFeverNativeGoldRoot.mainnet.address';

export const CHAIN = (process.env.REACT_APP_CHAIN as 'localhost' | 'goerli' | 'mainnet') || 'goerli';

export const BACKEND_URL: string = process.env.REACT_APP_BACKEND_URL || `https://api.goldfever.io/`;
export const ADMIN_API_URL: string = process.env.REACT_APP_ADMIN_API_URL || 'https://admin.goldfever.io/';

// export const SUBGRAPH_URL =
//   CHAIN === 'mumbai'
//     ? 'https://api.thegraph.com/subgraphs/name/cinoss/gold-fever-mumbai'
//     : 'http://localhost:8000/subgraphs/name/gold-fever-web/gold-fever';
export const RPC_URL =
  CHAIN === 'mainnet'
    ? 'https://mainnet.infura.io/v3/6f37278cbcab469ba8173a29ec48c8b6'
    : CHAIN === 'goerli'
    ? 'https://goerli.infura.io/v3/6f37278cbcab469ba8173a29ec48c8b6'
    : 'http://localhost:8545';

const CONTRACT_ADDRESSES =
  CHAIN === 'mainnet'
    ? {
        VESTING_CONTRACT_ADDRESS: MAINNET_VESTING_CONTRACT_ADDRESS,
        NGL_CONTRACT_ADDRESS: MAINNET_NGL_CONTRACT_ADDRESS,
      }
    : CHAIN === 'goerli'
    ? {
        VESTING_CONTRACT_ADDRESS: GOERLI_VESTING_CONTRACT_ADDRESS,
        NGL_CONTRACT_ADDRESS: GOERLI_NGL_CONTRACT_ADDRESS,
      }
    : {
        VESTING_CONTRACT_ADDRESS: require('./contracts/GoldFeverVesting.localhost.address'),
        NGL_CONTRACT_ADDRESS: GOERLI_NGL_CONTRACT_ADDRESS,
      };

export const { VESTING_CONTRACT_ADDRESS, NGL_CONTRACT_ADDRESS } = CONTRACT_ADDRESSES;
