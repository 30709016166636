import { useQuery, UseQueryResult, useMutation, UseMutationResult } from 'react-query';
import { useContractFunction } from '@usedapp/core';

import { Contract } from '@ethersproject/contracts';
import { VESTING_CONTRACT_ADDRESS } from '../config';
import GoldFeverVestingAbi from '../contracts/GoldFeverVesting.abi';

import { vestingContract } from './contracts';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';

export const useGetAvailableReward = (account: string): UseQueryResult<BigNumber> => {
  return useQuery(
    ['totalReward', account],
    async () => new BigNumber(await vestingContract.methods.getTotalRewards(account).call()),
  );
};

export function useGetClaimableReward(account: string): UseQueryResult<BigNumber> {
  return useQuery(
    ['claimAbleReward', account],
    async () => new BigNumber(await vestingContract.methods.getClaimAbleReward(account).call()),
  );
}

export function useGetClaimedReward(account: string): UseQueryResult<BigNumber> {
  return useQuery(
    ['claimedReward', account],
    async () => new BigNumber(await vestingContract.methods.getClaimedReward(account).call()),
  );
}

export function useClaimReward() {
  const { state, send } = useContractFunction(
    new Contract(VESTING_CONTRACT_ADDRESS, GoldFeverVestingAbi),
    'getReward',
    {
      transactionName: 'GetReward',
    },
  );
  const claimReward = () => {
    send();
  };

  return { claimReward, state };
}

export const useClaimRewardMutation = (): UseMutationResult<unknown, unknown, string, unknown> => {
  const { state, send } = useContractFunction(
    new Contract(VESTING_CONTRACT_ADDRESS, GoldFeverVestingAbi),
    'getReward',
    {
      transactionName: 'GetReward',
    },
  );
  return useMutation(async (account: string) => {
    await send();
    if (state.errorMessage) {
      toast.error(state.errorMessage);
    }
    toast.success(`Claim reward successfully.`);
    return account;
  });
};
