import BigNumber from 'bignumber.js';
import { useNglDecimalsQuery } from '../queries/ngl';
import Loader from '../assets/img/Q_Loader.gif';

export default function NglAmount(props: {
  amount?: BigNumber.Value;
  decimals?: number;
  isLoadingAmount?: boolean;
}): JSX.Element {
  const { data: decimals, isLoading: isLoadingDecimal } = useNglDecimalsQuery();

  return (
    <>
      {isLoadingDecimal || props.isLoadingAmount || props.amount === undefined ? (
        <img src={Loader} alt="loader" />
      ) : (
        new BigNumber(props.amount ?? 0).dividedBy(decimals ?? new BigNumber(10).pow(18)).toFixed(props.decimals ?? 5)
      )}
    </>
  );
}
