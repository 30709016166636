import React from 'react';
import '../src/scss/style.scss';
import Vesting from '../src/containers/vesting';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Vesting />
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
